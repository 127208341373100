import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import { Section } from '../components/section/section';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Title } from '../components/title/title';
import { Link } from '../components/link/link';
import { Subtitle } from '../components/subtitle/subtitle';
import { Image } from '../components/image/image';
import { Container } from '../components/container/container';
import { Content } from '../components/content/content';
import { ServiceCards } from '../components/service-cards/service-cards';
import { Overlay } from '../components/overlay/overlay';
import { LayoutContext } from '../components/layout/layout';
import { Meta } from '../components/meta/meta';

const ServiceTemplate = ({ data }) => {
  const { mdx } = data;
  const { frontmatter, body, excerpt } = mdx;

  const { title, ...meta } = frontmatter.meta || {};

  const { navOpen } = useContext(LayoutContext);

  return (
    <>
      <Meta title={title || frontmatter.title} description={excerpt} {...meta} />
      <Section layout="fullscreen" maxh={['none', 600]} css={{ backgroundColor: '#000' }}>
        <Image image={frontmatter.heroImage} type="bg" css={{ opacity: 0.4 }} />
        <Content h={1} css={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
          <Title mt="128px" color="#fff" hide={[navOpen, navOpen, 0]}>
            {frontmatter.name}
          </Title>
        </Content>
        <Content
          hide={[1, 0]}
          css={{
            position: 'absolute',
            bottom: 0,
            height: '80px',
            color: '#fff',
            backgroundColor: 'rgba(0, 133, 255, .2)',
            backdropFilter: 'blur(10px)',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Link
            to="https://www.google.com/maps/place/Chesterfield+Family+Chiropractic+Center/@42.6834603,-82.8273306,17z/data=!4m13!1m7!3m6!1s0x88251ed3fc238b5d:0x10a6e47136ed588c!2s28039+Carriage+Way+Dr,+New+Baltimore,+MI+48051!3b1!8m2!3d42.6834564!4d-82.8251419!3m4!1s0x88251f8cde259aa5:0xbcb58aa34e618757!8m2!3d42.6834767!4d-82.8261288"
            variant="unstyled"
            css={{
              width: '50%',
              maxWidth: '800px',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderLeft: '2px solid #ffffff80',
              borderRight: '1px solid #ffffff80',
            }}
          >
            Chesterfield Location | Get Directions
          </Link>
          <Link
            to="https://www.google.com/maps/place/Dr.+Todd's+Wellness/@42.6311226,-83.3271957,17z/data=!4m13!1m7!3m6!1s0x8824be62ae3b1645:0xf9dead869a3d58fc!2s133+S+Telegraph+Rd,+Pontiac,+MI+48341!3b1!8m2!3d42.6311187!4d-83.325007!3m4!1s0x8824bf0242fcd2e5:0x6b5d0cd35c28ef3f!8m2!3d42.6311191!4d-83.3249962"
            variant="unstyled"
            css={{
              width: '50%',
              maxWidth: '800px',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderLeft: '1px solid #ffffff80',
              borderRight: '2px solid #ffffff80',
            }}
          >
            Pontiac Location | Get Directions
          </Link>
        </Content>
        <Overlay open={navOpen} />
      </Section>
      <Section>
        <Container maxw={960}>
          <Subtitle>{frontmatter.title}</Subtitle>
          <MDXRenderer>{body}</MDXRenderer>
        </Container>
      </Section>
      <Section>
        <Container maxw={960}>
          <Subtitle>Our Other Services</Subtitle>
        </Container>
        <ServiceCards />
      </Section>
    </>
  );
};

export default ServiceTemplate;

export const pageQuery = graphql`
  query ServiceBySlug($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      excerpt
      frontmatter {
        title
        slug
        name
        heroImage {
          name
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
